"use strict";
/**
 * Spiral lock for forms
 * @constructor spiral.lock
 */
spiral.lock = {
    /**
     * Add lock
     * @param {String} type type of lock @see spiral.lock.lockTypes
     * @param {Object} context context to add lock
     * @returns {Function|*}
     */
    addLock: function (type, context) {
        if (this.lockTypes.hasOwnProperty(type)) {
            var node = document.createElement("div");
            node.className = "spiral-lock " + this.lockTypes[type].className;
            node.innerHTML = this.lockTypes[type].html;
            context.appendChild(node);
            context.classList.add("locked");
            return this.lockTypes[type].progress;
        }
        return false;
    },
    /**
     * Remove lock
     * @param {String} type type of lock
     * @param {Object} context
     */
    removeLock: function (type, context) {
        if (this.lockTypes.hasOwnProperty(type) && context) {
            context.classList.remove("locked");
            var spiralLock = context.querySelector(".spiral-lock");
            if (spiralLock)
                context.removeChild(spiralLock);
            return true;
        }
        return false;
    },
    /**
     * Object with lock types.
     * @enum {Object}

     */
    lockTypes: {
        /**
         * default lock type. <b>className:</b>spiral-lock-default
         * @type {Object}
         */
        "default": {
            /**
             * class name
             * @inner
             * @type String
             */
            className: "spiral-lock-default",
            /**
             * HTML
             * @inner
             * @type String
             */
            html: '<div id="circularG"><div id="circularG_1" class="circularG"></div><div id="circularG_2" class="circularG"></div><div id="circularG_3" class="circularG"></div><div id="circularG_4" class="circularG"></div><div id="circularG_5" class="circularG"></div><div id="circularG_6" class="circularG"></div><div id="circularG_7" class="circularG"></div><div id="circularG_8" class="circularG"></div></div>'
        },
        /**
         * opacity lock type.Just set opacity for node <b>className:</b>spiral-lock-opacity
         * @inner
         * @type Object
         */
        "opacity": {
            className: "spiral-lock-opacity",
            html: ''
        },
        /**
         * progress lock type. Show progress  <b>className:</b>spiral-lock-progress
         * @inner
         * @type Object
         */
        "progress": {
            className: "spiral-lock-progress",
            html: '<div class="progress progress-striped active"><div class="progress-bar"  role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"><span class="sr-only">0% Complete</span></div></div>',
            progress: function (current, total) {
                var progress = this.context.getElementsByClassName("progress-bar")[0],
                    sr = progress.getElementsByClassName("sr-only")[0],
                    percent = ''+100 * (current / total);
                progress.setAttribute("aria-valuenow", percent);
                progress.style.width = percent + "%";
                sr.innerHTML = percent + "%  Complete";
            }
        },
        /**
         * Moment lock type. <b>className:</b>spiral-lock-moment
         */

        "moment": {
            className: "spiral-lock-moment",
            html: '<div class="spiral-lock-moment-inner"><svg xmlns="http://www.w3.org/2000/svg" ><defs><filter id="blurFilter2" y="-10" height="50" x="-10" width="50"><feOffset in="SourceAlpha" dx="1" dy="1" result="offset2" /><feGaussianBlur in="offset2" stdDeviation="1.5"  result="blur2"/><feMerge><feMergeNode in="blur2" /><feMergeNode in="SourceGraphic" /></feMerge></filter></defs><circle cx="40" cy="40" r="32"/></svg></div>'
        },
        "da": {
            className: "spiral-lock-da",
            html: ''
        }

    }
};